<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-meeting-room-96.png"
          class="h-20 ml-2 inline-block"
        />
        گروه های من
      </h2>
      <p class="mt-3">گروه هایی که در آن ادمین هستید</p>
    </template>
    <template #default>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        <div
          v-for="group in groups"
          :key="group.id"
          class="px-5 bg-blueGray-100 border-blueGray-200 border rounded-md"
        >
          <h2 class="text-lg font-bold mt-5 mb-3">{{ group.name }}</h2>
          <div class="grid grid-cols-2 p-1">
            <div>ID</div>
            <div class="text-left" dir="ltr">{{ group.gid }}</div>
          </div>
          <div class="grid grid-cols-2 p-1">
            <div>ممبر</div>
            <div class="text-left" dir="ltr">{{ group.members }}</div>
          </div>
          <div class="grid grid-cols-2 p-1">
            <div>ربات</div>
            <div class="text-left" dir="ltr">
              {{ list_pro[group.pro_type] ?? "رایگان" }}
              <span v-if="group.pro_type && group.pro_exp?.days">
                ({{ group.pro_exp?.days }} روز)
              </span>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 mt-2 mb-4">
            <Button
              :to="{
                name: 'GroupOwnerSetting',
                params: { id: $store.state.user.id, groupId: group.id },
              }"
              class="text-sm h-10 lg:text-lg"
              color="sky"
            >
              تنظیمات
            </Button>
            <Button
              :to="{
                name: 'GroupOwnerBuy',
                params: { id: $store.state.user.id, groupId: group.id },
              }"
              class="text-sm h-10 lg:text-lg"
              color="green"
            >
              خرید ربات
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      groups: [],
      list_pro: {
        green: "سبز",
        blue: "آبی",
        bronze: "برنزی",
        silver: "نقره‌ای",
        golden: "طلایی",
      },
    };
  },
  mounted() {
    let $this = this;
    $this.$axios
      .get("/api/groups-owner", {
        params: {
          id: $this.$store.state.user.id,
        },
      })
      .then(function (response) {
        // console.log(response);
        $this.groups = response.data.groups;
      })
      .catch(function (error) {
        // console.log(error);
      })
      .finally(function () {
        // always executed
        $this.firstLoading = false;
      });
  },
};
</script>

<style></style>
